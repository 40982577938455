import { useState } from "react";
import MainDisplay from "../../components/imageDisplay/MainDisplay";
import Campaigns from "../../assets/PageAssets/Pvolve/01-Campaigns/campaign-cover-img-1.png";
import Partnerships from "../../assets/PageAssets/Pvolve/02-Partnerships/partnership-cover-img.jpg";
import ClinicalStudies from "../../assets/PageAssets/Pvolve/03-Clinical Studies/clinical-studies-cover-img.jpg";
import DayToDay from "../../assets/PageAssets/Pvolve/04-Day-to-Day/day-to-day-cover-img-2.jpg";
import "./pvolve.css";

const Pvolve = () => {
  const [projects] = useState([
    {
      projectName: "Campaigns",
      projectSource: Campaigns,
      projectPath: "/campaigns",
      projectClass: "campaigns",
    },
    {
      projectName: "Partnerships",
      projectSource: Partnerships,
      projectPath: "/partnerships",
      projectClass: "partnerships",
    },
    {
      projectName: "Clinical Studies",
      projectSource: ClinicalStudies,
      projectPath: "/clinical-studies",
      projectClass: "clinical-studies",
    },
    {
      projectName: "Day-to-Day",
      projectSource: DayToDay,
      projectPath: "/day-to-day",
      projectClass: "day-to-day",
    },
  ]);
  const companyClass = "Pvolve";
  const title = "Pvolve";
  const subText = "";
  return (
    <MainDisplay
      projects={projects}
      title={title}
      subText={subText}
      companyClass={companyClass}
    />
  );
};

export default Pvolve;
