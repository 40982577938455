import { useState, useContext } from "react";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import Paid1 from "../../../assets/PageAssets/Pvolve/04-Day-to-Day/01 Paid/paid-img-1.jpg";
import Paid2 from "../../../assets/PageAssets/Pvolve/04-Day-to-Day/01 Paid/paid-img-2.jpg";
import Paid3 from "../../../assets/PageAssets/Pvolve/04-Day-to-Day/01 Paid/paid-img-3.jpg";
import Paid4 from "../../../assets/PageAssets/Pvolve/04-Day-to-Day/01 Paid/paid-img-4.jpg";
import Paid5 from "../../../assets/PageAssets/Pvolve/04-Day-to-Day/01 Paid/paid-img-5.jpg";
import Paid6 from "../../../assets/PageAssets/Pvolve/04-Day-to-Day/01 Paid/paid-img-6.jpg";
import Sales1 from "../../../assets/PageAssets/Pvolve/04-Day-to-Day/03 Sales/sales-members-email-1.jpg";
import Sales2 from "../../../assets/PageAssets/Pvolve/04-Day-to-Day/03 Sales/sales-spring-sale-img-1.jpg";
import Emails2 from "../../../assets/PageAssets/Pvolve/04-Day-to-Day/04 Emails/email-in-press-email-2.jpg";
import Emails1 from "../../../assets/PageAssets/Pvolve/04-Day-to-Day/04 Emails/email-mental-email-1.jpg";
import Organic1 from "../../../assets/PageAssets/Pvolve/04-Day-to-Day/05 Organic/organic-img-1.jpg";
import "./dayToDay.css";
import SubPageNav from "../../../components/nav/SubPageNav";
import { WebPath } from "../../../components/context/appContext";

const DayToDay = (props) => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "image",
      projectSource: Paid1,
      projectClass: "day-to-day-1",
    },
    {
      projectType: "image",
      projectSource: Paid2,
      projectClass: "day-to-day-2",
    },
    {
      projectType: "image",
      projectSource: Paid3,
      projectClass: "day-to-day-3",
    },
    {
      projectType: "image",
      projectSource: Paid4,
      projectClass: "day-to-day-4",
    },
    {
      projectType: "image",
      projectSource: Paid5,
      projectClass: "day-to-day-5",
    },
    {
      projectType: "image",
      projectSource: Paid6,
      projectClass: "day-to-day-6",
    },
  ]);

  const projectNameOne = "day-to-day";
  const titleOne = "Day-To-Day";
  const subTextOne = "Paid";

  const [mediaThree] = useState([
    {
      projectType: "image",
      projectSource: Sales1,
      projectClass: "day-to-day-7",
    },
    {
      projectType: "image",
      projectSource: Sales2,
      projectClass: "day-to-day-8",
    },
  ]);

  const projectNameThree = "day-to-day3";
  const titleThree = "Sales";
  const subTextThree = "";

  const [mediaFour] = useState([
    {
      projectType: "image",
      projectSource: Emails1,
      projectClass: "day-to-day-9",
    },
    {
      projectType: "image",
      projectSource: Emails2,
      projectClass: "day-to-day-10",
    },
  ]);

  const projectNameFour = "day-to-day4";
  const titleFour = "Emails";
  const subTextFour = "";

  const [mediaFive] = useState([
    {
      projectType: "image",
      projectSource: Organic1,
      projectClass: "day-to-day-11",
    },
    {
      projectType: "instagram",
      projectSource: "https://www.instagram.com/p/C3v0B1EuNJS/embed/",
      projectClass: "day-to-day-12",
    },
    {
      projectType: "instagram",
      projectSource: "https://www.instagram.com/p/CyRY6I_vb8P/embed/",
      projectClass: "day-to-day-13",
    },
  ]);

  const projectNameFive = "day-to-day5";
  const titleFive = "Organic";
  const subTextFive = "";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <div className="day-to-day-link-container">
        <div className="day-to-day-link-text">
          Click{" "}
          <a
            className="day-to-day-link-highlighted-text"
            href="https://www.pvolve.com/pages/method"
          >
            here
          </a>{" "}
          for the latest Pvolve Method Landing Page.
        </div>
      </div>
      <ProjectDisplay
        media={mediaThree}
        projectName={projectNameThree}
        title={titleThree}
        subText={subTextThree}
      />
      <ProjectDisplay
        media={mediaFour}
        projectName={projectNameFour}
        title={titleFour}
        subText={subTextFour}
      />
      <ProjectDisplay
        media={mediaFive}
        projectName={projectNameFive}
        title={titleFive}
        subText={subTextFive}
      />
      <SubPageNav projectPaths={projectPaths.pvolve} />
    </div>
  );
};

export default DayToDay;
