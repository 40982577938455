import { useState, useContext } from "react";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import LowerBack1 from "../../../assets/PageAssets/Pvolve/03-Clinical Studies/01 Lower Back Support/lower-back-email-1.jpg";
import LowerBack2 from "../../../assets/PageAssets/Pvolve/03-Clinical Studies/01 Lower Back Support/lower-back-img-1.jpg";
import HealthyAging1 from "../../../assets/PageAssets/Pvolve/03-Clinical Studies/02 Healthy Aging/healthy-aging-img-1.jpg";
import HealthyAging2 from "../../../assets/PageAssets/Pvolve/03-Clinical Studies/02 Healthy Aging/healthy-aging-img-2.jpg";
import Recover1 from "../../../assets/PageAssets/Pvolve/03-Clinical Studies/03 Recover 9 Post Workout/recover-9-img-1.jpg";
import Recover2 from "../../../assets/PageAssets/Pvolve/03-Clinical Studies/03 Recover 9 Post Workout/recover-9-img-2.jpg";
import "./clinicalStudies.css";
import SubPageNav from "../../../components/nav/SubPageNav";
import { WebPath } from "../../../components/context/appContext";

const ClinicalStudies = (props) => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "image",
      projectSource: LowerBack1,
      projectClass: "clinical-studies-1",
    },
    {
      projectType: "image",
      projectSource: LowerBack2,
      projectClass: "clinical-studies-2",
    },
  ]);

  const projectNameOne = "clinical-studies";
  const titleOne = "Clinical Studies";
  const subTextOne = "Lower Back Support";

  const [mediaTwo] = useState([
    {
      projectType: "image",
      projectSource: HealthyAging1,
      projectClass: "clinical-studies-3",
    },
    {
      projectType: "image",
      projectSource: HealthyAging2,
      projectClass: "clinical-studies-4",
    },
  ]);

  const projectNameTwo = "clinical-studies2";
  const titleTwo = "Healthy Aging";
  const subTextTwo = "";

  const [mediaThree] = useState([
    {
      projectType: "image",
      projectSource: Recover1,
      projectClass: "clinical-studies-5",
    },
    {
      projectType: "image",
      projectSource: Recover2,
      projectClass: "clinical-studies-6",
    },
  ]);

  const projectNameThree = "clinical-studies3";
  const titleThree = "Recover 9 Post Workout";
  const subTextThree = "";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <ProjectDisplay
        media={mediaTwo}
        projectName={projectNameTwo}
        title={titleTwo}
        subText={subTextTwo}
      />
      <ProjectDisplay
        media={mediaThree}
        projectName={projectNameThree}
        title={titleThree}
        subText={subTextThree}
      />
      <SubPageNav projectPaths={projectPaths.pvolve} />
    </div>
  );
};

export default ClinicalStudies;
