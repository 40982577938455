import { useState, useContext } from "react";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import Nutrafol1 from "../../../assets/PageAssets/Pvolve/02-Partnerships/01 Nutrafol/nutrafol-email-1.jpg";
import Nutrafol2 from "../../../assets/PageAssets/Pvolve/02-Partnerships/01 Nutrafol/nutrafol-img-1.jpg";
import Apple1 from "../../../assets/PageAssets/Pvolve/02-Partnerships/02 Apple TV/apple-gif-1.gif";
import Hamptons1 from "../../../assets/PageAssets/Pvolve/02-Partnerships/03 Hamptons/hamptons-email-1.jpg";
import Hamptons2 from "../../../assets/PageAssets/Pvolve/02-Partnerships/03 Hamptons/hamptons-img-1.jpg";
import "./partnerships.css";
import SubPageNav from "../../../components/nav/SubPageNav";
import { WebPath } from "../../../components/context/appContext";

const Partnerships = (props) => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "image",
      projectSource: Nutrafol1,
      projectClass: "partnerships-1",
    },
    {
      projectType: "image",
      projectSource: Nutrafol2,
      projectClass: "partnerships-3",
    },
  ]);

  const projectNameOne = "partnerships";
  const titleOne = "Partnerships";
  const subTextOne = "Nutrafol";

  const [mediaTwo] = useState([
    {
      projectType: "image",
      projectSource: Apple1,
      projectClass: "partnerships-4",
    },
  ]);

  const projectNameTwo = "partnerships2";
  const titleTwo = "Apple TV";
  const subTextTwo = "";

  const [mediaThree] = useState([
    {
      projectType: "image",
      projectSource: Hamptons1,
      projectClass: "partnerships-5",
    },
    {
      projectType: "image",
      projectSource: Hamptons2,
      projectClass: "partnerships-6",
    },
  ]);

  const projectNameThree = "partnerships3";
  const titleThree = "Hamptons Residency";
  const subTextThree = "";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <ProjectDisplay
        media={mediaTwo}
        projectName={projectNameTwo}
        title={titleTwo}
        subText={subTextTwo}
      />
      <ProjectDisplay
        media={mediaThree}
        projectName={projectNameThree}
        title={titleThree}
        subText={subTextThree}
      />
      <SubPageNav projectPaths={projectPaths.pvolve} />
    </div>
  );
};

export default Partnerships;
