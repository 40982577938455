import { useState, useContext } from "react";
import { WebPath } from "../../../components/context/appContext";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import SubPageNav from "../../../components/nav/SubPageNav";
import Valentine1 from "../../../assets/PageAssets/Pvolve/01-Campaigns/02 Valentines Day/Valentine-img-1.jpg";
import Valentine2 from "../../../assets/PageAssets/Pvolve/01-Campaigns/02 Valentines Day/Valentine-img-2.jpg";
import Moments1 from "../../../assets/PageAssets/Pvolve/01-Campaigns/03 Moments We Train For/Moments-we-train-email.jpg";
import Summer1 from "../../../assets/PageAssets/Pvolve/01-Campaigns/04 Summer of Pvolve/Summer-pvolve-email-1.jpg";
import Summer2 from "../../../assets/PageAssets/Pvolve/01-Campaigns/04 Summer of Pvolve/Summer-pvolve-email-2.jpg";
import Summer3 from "../../../assets/PageAssets/Pvolve/01-Campaigns/04 Summer of Pvolve/Summer-pvolve-img-1.jpg";
import NewYear1 from "../../../assets/PageAssets/Pvolve/01-Campaigns/05 New Year/New-year-email-img-1.jpg";
import NewYear2 from "../../../assets/PageAssets/Pvolve/01-Campaigns/05 New Year/New-year-img-2.jpg";
import NewYear3 from "../../../assets/PageAssets/Pvolve/01-Campaigns/05 New Year/New-year-img-3.jpg";

import "./campaigns.css";

const Campaigns = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "image",
      projectSource: Valentine1,
      projectClass: "campaigns-1",
    },
    {
      projectType: "image",
      projectSource: Valentine2,
      projectClass: "campaigns-2",
    },
    {
      projectType: "instagram-picture",
      projectSource: "https://www.instagram.com/p/C3VVzejO_78/embed/",
      projectClass: "campaigns-3",
    },
  ]);

  const projectNameOne = "campaigns";
  const titleOne = "Campaigns";
  const subTextOne = "Valentine's Day";

  const [mediaTwo] = useState([
    {
      projectType: "image",
      projectSource: Moments1,
      projectClass: "campaigns-4",
    },
    {
      projectType: "instagram",
      projectSource: "https://www.instagram.com/p/C_LKAsRxOP1/embed/",
      projectClass: "campaigns-5",
    },
  ]);

  const projectNameTwo = "campaigns2";
  const titleTwo = "The Moments We Train For";
  const subTextTwo = "";

  const [mediaThree] = useState([
    {
      projectType: "image",
      projectSource: Summer1,
      projectClass: "campaigns-6",
    },
    {
      projectType: "image",
      projectSource: Summer2,
      projectClass: "campaigns-7",
    },
    {
      projectType: "image",
      projectSource: Summer3,
      projectClass: "campaigns-8",
    },
  ]);

  const projectNameThree = "campaigns3";
  const titleThree = "Summer of Pvolve";
  const subTextThree = "";

  const [mediaFour] = useState([
    {
      projectType: "image",
      projectSource: NewYear1,
      projectClass: "campaigns-9",
    },
    {
      projectType: "image",
      projectSource: NewYear2,
      projectClass: "campaigns-10",
    },
    {
      projectType: "image",
      projectSource: NewYear3,
      projectClass: "campaigns-11",
    },
  ]);

  const projectNameFour = "campaigns4";
  const titleFour = "New Year";
  const subTextFour = "";

  const [mediaFive] = useState([
    {
      projectType: "image",
      projectSource: Moments1,
      projectClass: "campaigns-4",
    },
    {
      projectType: "instagram",
      projectSource: "https://www.instagram.com/p/C_LKAsRxOP1/embed/",
      projectClass: "campaigns-5",
    },
  ]);

  const projectNameFive = "campaigns5";
  const titleFive = "Strength";
  const subTextFive = "";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <ProjectDisplay
        media={mediaTwo}
        projectName={projectNameTwo}
        title={titleTwo}
        subText={subTextTwo}
      />
      <ProjectDisplay
        media={mediaThree}
        projectName={projectNameThree}
        title={titleThree}
        subText={subTextThree}
      />
      <ProjectDisplay
        media={mediaFour}
        projectName={projectNameFour}
        title={titleFour}
        subText={subTextFour}
      />
      {/* <ProjectDisplay
        media={mediaFive}
        projectName={projectNameFive}
        title={titleFive}
        subText={subTextFive}
      /> */}

      <SubPageNav projectPaths={projectPaths.pvolve} />
    </div>
  );
};

export default Campaigns;
