import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const NavPaths = (props) => {
  const { dropDown, closeDropDown } = props;
  const location = useLocation();

  const [url, setUrl] = useState(null);

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const createdPaths = [
    {
      linkName: "Home",
      linkPath: "/",
      itemClassName: "nav-item-home",
    },
    {
      linkName: "Pvolve",
      linkPath: "/pvolve",
      itemClassName: "nav-item-pvolve",
    },
    {
      linkName: "Therabody",
      linkPath: "/therabody",
      itemClassName: "nav-item-therabody",
    },
    {
      linkName: "Peloton",
      linkPath: "/peloton",
      itemClassName: "nav-item-peloton",
    },
    {
      linkName: "Mercedes-Benz",
      linkPath: "/mercedes-benz",
      itemClassName: "nav-item-mercedes-benz",
    },
    // {
    //   linkName: "Internship",
    //   linkPath: "/internship",
    //   itemClassName: "nav-item-internship",
    // },
    {
      linkName: "Contact",
      linkPath: "/contact",
      itemClassName: "nav-item-contact",
    },
  ];

  let navLinks = createdPaths.map((path) => (
    <Link
      to={path.linkPath}
      className={"nav-link" + (url === path.linkPath ? " active" : "")}
      key={path.linkName}
      onMouseOver={(e) => {
        e.currentTarget.style.borderBottom = "2px solid #333";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.borderBottom = "2px solid #f8f8f8";
      }}
      onClick={() => {
        if (dropDown) {
          closeDropDown();
        }
      }}
    >
      {path.linkName}
    </Link>
  ));

  return navLinks;
};

export default NavPaths;
