import React, { useState } from "react";
import "./app.css";
import Nav from "./components/nav/Nav";
import Home from "./pages/home/Home";
import Peloton from "./pages/peloton/Peloton";
import Therabody from "./pages/therabody/Therabody";
import MercedesBenz from "./pages/mercedes-benz/MercedesBenz";
import Pvolve from "./pages/pvolve/Pvolve";
import Internship from "./pages/internship/Internship";
import Contact from "./pages/contact/Contact";
import { HashRouter, Routes, Route } from "react-router-dom";
import { WebPath } from "./components/context/appContext";
import Apparel from "./pages/peloton/projects/Apparel";
import BikeLaunch from "./pages/peloton/projects/BikeLaunch";
import Holiday from "./pages/peloton/projects/Holiday";
import InstructorLaunches from "./pages/peloton/projects/InstructorLaunches";
import NewFeatureLaunches from "./pages/peloton/projects/NewFeatureLaunches";
import Pelothon from "./pages/peloton/projects/Pelothon";
import LastFanStanding from "./pages/mercedes-benz/projects/LastFanStanding";
import EventsAndSocial from "./pages/mercedes-benz/projects/EventsAndSocial";
import MercedesBenzGames from "./pages/mercedes-benz/projects/MercedesBenzGames";
import Summer from "./pages/mercedes-benz/projects/Summer";
import SmartUSA from "./pages/mercedes-benz/projects/SmartUSA";
import GoogleFlights from "./pages/internship/projects/GoogleFlights";
import Dramamine from "./pages/internship/projects/Dramamine";
import FathersDay from "./pages/therabody/projects/FathersDay";
import KeepMoving from "./pages/therabody/projects/KeepMoving";
import Revolve from "./pages/therabody/projects/Revolve";
import ValentinesDay from "./pages/therabody/projects/ValentinesDay";
import Campaigns from "./pages/pvolve/projects/campaigns";
import Partnerships from "./pages/pvolve/projects/partnerships";
import ClinicalStudies from "./pages/pvolve/projects/clinicalStudies";
import DayToDay from "./pages/pvolve/projects/dayToDay";

const App = () => {
  // Each time a new company tab is made, an object representing all
  // of the companie's projects should be made.
  // "Additional work" is an exception
  // These path's will be added as directories onto the subPages
  const [projectPaths] = useState({
    peloton: [
      {
        projectName: "All",
        projectPath: "/",
        projectClass: "peloton",
      },
      {
        projectName: "Pelothon 2020",
        projectPath: "/pelothon2020",
        projectClass: "pelothon",
      },
      {
        projectName: "New Feature Launches",
        projectPath: "/new-feature-launches",
        projectClass: "new-feature-launches",
      },
      {
        projectName: "Peloton Bike+ Launch",
        projectPath: "/peloton-bike-launch",
        projectClass: "peloton-bike-launch",
      },
      {
        projectName: "Holiday",
        projectPath: "/holiday",
        projectClass: "holiday",
      },
      {
        projectName: "Instructor Launches",
        projectPath: "/instructor-launches",
        projectClass: "instructor-launches",
      },
      {
        projectName: "Apparel",
        projectPath: "/apparel",
        projectClass: "apparel",
      },
    ],
    additionalWork: [
      {
        projectName: "All",
        projectPath: "/internship",
        projectClass: "internship",
      },
      {
        projectName: "Dramamine",
        projectPath: "/dramamine",
        projectClass: "dramamine",
      },
      {
        projectName: "Google Flights",
        projectPath: "/google-flights",
        projectClass: "google-flights",
      },
    ],
    mercedesBenz: [
      {
        projectName: "All",
        projectPath: "/mercedes-benz",
        projectClass: "mercedes-benz",
      },
      {
        projectName: "Last Fan Standing",
        projectPath: "/last-fan-standing",
        projectClass: "last-fan-standing",
      },
      {
        projectName: "Events & Social Content",
        projectPath: "/events-and-social",
        projectClass: "events-and-social",
      },
      {
        projectName: "The Mercedes-Benz Stadium Games",
        projectPath: "/mercedes-benz-games",
        projectClass: "peloton-bike-launch",
      },
      {
        projectName: "#MBSummer",
        projectPath: "/summer",
        projectClass: "holiday",
      },
      {
        projectName: "Smart USA",
        projectPath: "/smart-USA",
        projectClass: "instructor-launches",
      },
    ],
    therabody: [
      {
        projectName: "All",
        projectPath: "/therabody",
        projectClass: "therabody",
      },
      {
        projectName: "Cody Bellinger X Father's Day",
        projectPath: "/cody-bellinger-fathers-day",
        projectClass: "cody-bellinger-fathers-day",
      },
      {
        projectName: "Revolve Wellness Oasis",
        projectPath: "/revolve",
        projectClass: "revolve",
      },
      {
        projectName: "Keep Moving",
        projectPath: "/keep-moving",
        projectClass: "keep-moving",
      },
      {
        projectName: "Valentine's Day",
        projectPath: "/valentines-day",
        projectClass: "valentines-day",
      },
    ],
    pvolve: [
      {
        projectName: "All",
        projectPath: "/pvolve",
        projectClass: "pvolve",
      },
      {
        projectName: "Campaigns",
        projectPath: "/campaigns",
        projectClass: "campaigns",
      },
      {
        projectName: "Partnerships",
        projectPath: "/partnerships",
        projectClass: "partnerships",
      },
      {
        projectName: "Clinical Studies",
        projectPath: "/clinical-studies",
        projectClass: "clinical-studies",
      },
      {
        projectName: "Day-to-Day",
        projectPath: "/day-to-day",
        projectClass: "day-to-day",
      },
    ],
  });

  return (
    <div className="App">
      <HashRouter>
        <WebPath.Provider value={{ projectPaths }}>
          <Nav />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/peloton" element={<Peloton />} />
            <Route path="/apparel" element={<Apparel />} />
            <Route path="/peloton-bike-launch" element={<BikeLaunch />} />
            <Route path="/holiday" element={<Holiday />} />
            <Route
              path="/instructor-launches"
              element={<InstructorLaunches />}
            />
            <Route
              path="/new-feature-launches"
              element={<NewFeatureLaunches />}
            />
            <Route path="/pelothon2020" element={<Pelothon />} />
            <Route path="/therabody" element={<Therabody />} />
            <Route
              path="/cody-bellinger-fathers-day"
              element={<FathersDay />}
            />
            <Route path="/keep-moving" element={<KeepMoving />} />
            <Route path="/revolve" element={<Revolve />} />
            <Route path="/valentines-day" element={<ValentinesDay />} />

            <Route path="/mercedes-benz" element={<MercedesBenz />} />
            <Route path="/internship" element={<Internship />} />
            <Route path="/google-flights" element={<GoogleFlights />} />
            <Route path="/dramamine" element={<Dramamine />} />
            <Route path="/last-fan-standing" element={<LastFanStanding />} />
            <Route path="/events-and-social" element={<EventsAndSocial />} />
            <Route
              path="/mercedes-benz-games"
              element={<MercedesBenzGames />}
            />
            <Route path="/summer" element={<Summer />} />
            <Route path="/smart-USA" element={<SmartUSA />} />

            <Route path="/pvolve" element={<Pvolve />} />
            <Route path="/campaigns" element={<Campaigns />} />
            <Route path="/partnerships" element={<Partnerships />} />
            <Route path="/clinical-studies" element={<ClinicalStudies />} />
            <Route path="/day-to-day" element={<DayToDay />} />

            <Route path="/contact" element={<Contact />} />
          </Routes>
          {/* <Footer /> */}
        </WebPath.Provider>
      </HashRouter>
    </div>
  );
};

export default App;
